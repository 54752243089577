.embla {
    --slide-spacing: 1rem;
    --slide-size: 60%;
    --slide-height: 15rem;
    /* padding: 1.6rem; */
  }
  @media screen and (min-width: 640px) {
    .embla {
      --slide-spacing: 1rem;
      --slide-size: 100%;
      --slide-height: 15rem;
      padding: 1rem;
    }
  }
  @media screen and (min-width: 768px) {
    .embla {
      --slide-spacing: 1rem;
      --slide-size: 80%;
      --slide-height: 15rem;
      padding: 1rem;
    }
  }
  @media screen and (min-width: 800px) {
    .embla {
      --slide-spacing: 1rem;
      --slide-size: 70%;
      --slide-height: 15rem;
      padding: 1rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .embla {
      --slide-spacing: 1rem;
      --slide-size: 60%;
      --slide-height: 15rem;
      padding: 1rem
    }
  }

  .embla__viewport {
    overflow: hidden;
  }  

  .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
  }
  .embla__slide__img {
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
  }
  .embla__slide__number {
    width: 4.6rem;
    height: 4.6rem;
    z-index: 1;
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    border-radius: 50%;
    background-color: rgba(var(--background-site-rgb-value), 0.85);
    line-height: 4.6rem;
    font-weight: 900;
    text-align: center;
    pointer-events: none;
  }
  .embla__slide__number > span {
    color: var(--brand-primary);
    background-image: linear-gradient(
      45deg,
      var(--brand-primary),
      var(--brand-secondary)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.6rem;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  