@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,700&family=Gloock&family=IBM+Plex+Sans:ital,wght@1,100&family=Josefin+Sans&family=Jura:wght@700&family=Lexend&family=Montserrat:ital,wght@0,300;0,400;0,500;1,300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

 #skillsSection, #projectection, #contactSection, #projectsSection, #homeSection, #aboutSection {
    background-Image: url(/public/img/finaltexture.png);
    background-Size: cover;
    background-Repeat: no-repeat;
    background-Position: bottom;
    height: 100vh;
    width: 100vw;
  }